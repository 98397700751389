// React imports

import { useEffect, useState } from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports

const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
    }
  }, [isMounted])

  return { isMounted }
}

export default useIsMounted
